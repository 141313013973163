export const NewDomainRecommendationTunnels = [
  'domain',
  'domain_ksite',
  'dns',
  'dns_free',
  'hosting',
  'email_hosting',
  'vps',
];
export const DomainRecommendationTunnels = ['vps_only', 'jelastic', 'public_cloud', 'streaming_video'];
