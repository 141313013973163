import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Navigation } from '@app/models/navigation';
import { SwitchAccount } from '@app/store/actions/account.action';
import { SetNavigation } from '@app/store/actions/cart.action';
import { CartState } from '@app/store/state/cart.state';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthenticationService, I18nService } from '@app/core';
import { MatomoTrackerService } from '@app/core/matomo-tracker.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class SharedHeaderComponent implements OnInit, OnDestroy {
  @Input() reloadDataOnLangageChange = true;
  user = window['CONST_USER'];
  currAccount = window['CURRENT_ACCOUNT'];
  orderState =
    window['CURRENT_STATE'].includes('order') ||
    !['login', 'payment', 'change_account', '/support', 'secure-profile', 'satisfaction'].reduce(
      (previous, current) => previous || window.location.href.includes(current),
      false
    );
  vatToggleConfig =
    window['TAX_MODE'] !== undefined
      ? window['TAX_MODE']
      : {
          incl_vat: true,
          force_excluded_vat: true,
          disabled: false,
          tooltip: '',
          country_has_vat: true,
        };
  total = 0;
  menuHidden = true;
  isAutolog = window['IS_AUTOLOG'];
  config = {
    language: '',
  };
  productName = window.location.host.split('.')[0];
  isMobile = false;
  isTablet = false; // Only used by ttc switch to moove from header if width < 1200px
  isLastStep = false;

  @Select(CartState.links)
  links$: Observable<any>;
  @Select(CartState.accounts)
  accounts$: Observable<any>;
  @Select(CartState.navigation)
  navigation$: Observable<Navigation>;

  @Select(CartState)
  cart$: Observable<any>;
  _cartId = '';
  type = '';
  status = null;

  displayedSteps = [];
  displayedActiveRoute: number;

  infomaniakLogoPath = '/assets/images/logo-infomaniak.svg';

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private i18nService: I18nService,
    public breakpointObserver: BreakpointObserver,
    private store: Store,
    private matomoTracker: MatomoTrackerService
  ) {}

  get hasWhiteBackground(): boolean {
    return (
      window.location.pathname.includes('/ksuite_change_offer') ||
      window.location.pathname.includes('/support_premium_change_offer') ||
      window.location.pathname.includes('order2/recommendation') ||
      window.location.pathname.includes('order2/select') ||
      window.location.pathname.includes('order2/payment') ||
      window.location.pathname.includes('order/payment')
    );
  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

  get isLogged(): boolean {
    return Boolean(this.currAccount && this.currAccount.organization_name);
  }

  ngOnInit() {
    this.config.language = this.currentLanguage;

    this.breakpointObserver
      .observe(['(max-width: 767px)'])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        if (result.matches) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      });
    this.breakpointObserver
      .observe(['(max-width: 1200px)'])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        if (result.matches) {
          this.isTablet = true;
        } else {
          this.isTablet = false;
        }
      });
    this.cart$.pipe(takeUntil(this.onDestroy$)).subscribe((state) => {
      if (state && state.cart) {
        this._cartId = state.cart.uuid;
        this.type = state.cart.summary && state.cart.summary[0] && state.cart.summary[0].type;
        this.status = state.cart.setup && state.cart.setup.status;
      }
    });
    this.navigation$.pipe(takeUntil(this.onDestroy$)).subscribe((nav) => {
      if (nav && nav.workflow) {
        this.displayedSteps = nav.workflow
          .filter((x) => !x.hidden)
          .map((x) => {
            return {
              title: x.label,
              rank: x.order,
            };
          })
          .sort((a, b) => a.rank - b.rank);
        const active_step = nav.workflow.find((x) => x.order === nav.current_step);
        this.orderState =
          window['CURRENT_STATE'].includes('order') ||
          (!['login', 'payment', 'change_account'].includes(active_step.name) &&
            window['CURRENT_STATE'] !== 'secure_profile');
        // We reduce nav.current_step by 1 for each hidden step before the current one
        this.displayedActiveRoute = nav.current_step;
        // for (let i = 0; i < nav.current_step; i++) {
        //     if (nav.workflow[i].hidden) {
        //         this.displayedActiveRoute--;
        //     }
        // }
        // this.displayedActiveRoute = nav.current_step - nav.workflow.slice(0, nav.current_step).reduce((acc, step) => acc += +!!step.hidden, 0);
        this.isLastStep = nav.current_step === Math.max(...this.displayedSteps.map((step) => step.rank));
      }
    });
  }

  toggleMenu() {
    this.menuHidden = !this.menuHidden;
  }

  setLanguage(language: string) {
    this.i18nService.setLanguage(language, this.reloadDataOnLangageChange);
  }

  logout() {
    this.authenticationService
      .logout()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.router.navigate(['auth/login'], { replaceUrl: true }));
  }

  setAccount(id: string) {
    this.store.dispatch(new SwitchAccount({ account_id: id, context: 'menu' }));
  }

  getInitial() {
    const displayName = this.user.first_name?.trim() + ' ' + this.user.last_name?.trim();
    const splitted = displayName.split(' ');
    return (
      (splitted[0].length > 0 ? splitted[0][0].toUpperCase() : '') +
      (splitted[splitted.length - 1].length > 0 ? splitted[splitted.length - 1][0].toUpperCase() : '')
    );
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  onTotal(output: any) {
    this.total = output;
  }

  goToAutologout() {
    window.location.assign('/auth/autologout');
  }

  isChangeOffer() {
    return this.type.indexOf('change_offer') >= 0;
  }

  switchLink(links) {
    if (Object.keys(window['CONST_USER']).length > 0) {
      return links?.manager || 'https://manager.infomaniak.com';
    } else {
      return links.hosting;
    }
  }

  /**
   * NAVIGATION BAR
   */
  tryNavigateFromNavigationBar(rank: number, target?: number) {
    this.store.dispatch(
      new SetNavigation({
        current_step: this.displayedSteps.find((x) => x.rank === rank).title,
        target: target || 0,
      })
    );
  }

  onShowCart() {
    this.matomoTracker.trackClick('button_show-cart');
  }
}
