import { Routes } from '@angular/router';

export const SIGNUP_ROUTES: Routes = [
  {
    path: 'signup',
    loadChildren: () => import('./signup.module').then((m) => m.SignupModule),
  },
  {
    path: 'signup/account',
    loadChildren: () => import('./signup.module').then((m) => m.SignupModule),
  },
  {
    path: 'signup/affiliation',
    loadChildren: () => import('./signup.module').then((m) => m.SignupModule),
  },
  {
    path: 'signup/safetracing',
    loadChildren: () => import('./signup.module').then((m) => m.SignupModule),
  },
  {
    path: 'signup/etickets',
    loadChildren: () => import('./signup.module').then((m) => m.SignupModule),
  },
  {
    path: 'signup/promotional',
    loadChildren: () => import('./signup.module').then((m) => m.SignupModule),
  },
  {
    path: 'signup/ikmail',
    loadChildren: () => import('./signup.module').then((m) => m.SignupModule),
    data: { type: 'myksuite' },
  },
  {
    path: 'signup/ikdrive',
    loadChildren: () => import('./signup.module').then((m) => m.SignupModule),
    data: { type: 'myksuite' },
  },
  {
    path: 'signup/myksuite',
    loadChildren: () => import('./signup.module').then((m) => m.SignupModule),
    data: { type: 'myksuite' },
  },
  {
    path: 'signup/restricted',
    loadChildren: () => import('./signup.module').then((m) => m.SignupModule),
  },
  {
    path: 'signup/student',
    loadChildren: () => import('./signup.module').then((m) => m.SignupModule),
  },
  {
    path: 'signup/student/renew',
    loadChildren: () => import('./signup.module').then((m) => m.SignupModule),
  },
];
