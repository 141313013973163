<section *ngIf="ready">
    <div class="container margin80">
        <div class="margin-top-20">
            <h2 class="text-center">🦄 DEVELOPMENT ENDPOINT ONLY 🦄</h2>
            <h1 class="text-center">🐧</h1>
            <div class="text-justify">
                Auth user without CURRENT_STATE are redirected to admin3
                <br />
                None auth user without CURRENT_STATE are redirected to site-hosting
            </div>
        </div>
        <div class="margin-top-20 padding-bottom-50">
            <div class="text-justify">
                <div>
                    <a href="/change_account">
                        <button class="blue link text-capitalize">Change account</button>
                    </a>
                </div>
                <div>
                    <a href="/signup">
                        <button class="blue link text-capitalize">Signup</button>
                    </a>
                </div>
                <hr />
                <div>
                    <a href="/order/swiss_backup">
                        <button class="blue link text-capitalize">Swiss Backup (empty)</button>
                    </a>
                </div>
                <div>
                    <a href="/order/swiss_backup?slot=1&disk_size=200&period=36&trial=0">
                        <button class="blue link text-capitalize">Swiss Backup (preconfigured)</button>
                    </a>
                </div>
                <div>
                    <a [href]="'/order/swiss_backup/' + productId">
                        <button [disabled]="!productId" class="blue link text-capitalize">
                            Swiss Backup (change_offer)
                        </button>
                    </a>
                    <input [(ngModel)]="productId" placeholder="id change_offer" type="text" />
                </div>
                <hr />
                <div>
                    <a href="/order/drive">
                        <button class="blue link text-capitalize">Drive (empty)</button>
                    </a>
                </div>
                <div>
                    <a href="/order/drive/{{ driveId }}">
                        <button [disabled]="!driveId" class="blue link text-capitalize">Drive (change_offer)</button>
                    </a>
                    <input [(ngModel)]="driveId" placeholder="id drive" type="text" />
                </div>
                <div>
                    <a href="/order/drive_offer_qoqa_neonq">
                        <button class="blue link text-capitalize">Drive (Offer QoQa + NeonQ)</button>
                    </a>
                </div>
                <hr />
                <div>
                    <a href="/order/jelastic">
                        <button class="blue link text-capitalize">Jelastic (empty)</button>
                    </a>
                </div>
                <hr />
                <div>
                    <a href="/order/invitation/{{ invitationId }}">
                        <button [disabled]="!invitationId" class="blue link text-capitalize">
                            Invitation (change_offer)
                        </button>
                    </a>
                    <input [(ngModel)]="invitationId" placeholder="id invitation" type="text" />
                </div>
                <hr />
                <div>
                    <a href="/order/invitation/{{ newsletterId }}">
                        <button [disabled]="!newsletterId" class="blue link text-capitalize">
                            Newsletter (change_offer)
                        </button>
                    </a>
                    <input [(ngModel)]="newsletterId" placeholder="id newsletter" type="text" />
                </div>
                <hr />
                <div>
                    <a href="/order/bulk_transfer_domain">
                        <button class="blue link text-capitalize">Bulk Transfer Domain (empty)</button>
                    </a>
                </div>
                <div>
                    <a href="/order/bulk_transfer_domain?domains[]=test1.ch%20AuthCode">
                        <button class="blue link text-capitalize">Bulk Transfer Domain (preconfigured)</button>
                    </a>
                </div>
                <hr />
                <div>
                    <a href="/order2/domain?initializer=true">
                        <button class="blue link text-capitalize">Domain Name (empty)</button>
                    </a>
                </div>
                <hr />
                <div>
                    <a href="/order/streaming_video">
                        <button class="blue link text-capitalize">Streaming_video (empty)</button>
                    </a>
                </div>
                <div>
                    <a href="/order/streaming_video?pack_id=4&period=1&additionalStream=0&trial=1">
                        <button class="blue link text-capitalize">Streaming_video (preconfigured)</button>
                    </a>
                </div>
                <div>
                    <a [href]="'/order/streaming_video/' + product_videoId">
                        <button [disabled]="!product_videoId" class="blue link text-capitalize">
                            Streaming_video (change_offer)
                        </button>
                    </a>
                    <input [(ngModel)]="product_videoId" placeholder="channel id" type="text" />
                </div>
                <hr />
                <div>
                    <a href="/order/vod">
                        <button class="blue link text-capitalize">VOD (empty)</button>
                    </a>
                </div>
                <div>
                    <a href="/order/vod/{{ channelId }}">
                        <button [disabled]="!channelId" class="blue link text-capitalize">VOD (change_offer)</button>
                    </a>
                    <input
                        (keyup.enter)="onEnter('/order/vod/' + channelId)"
                        [(ngModel)]="channelId"
                        placeholder="id channel"
                        type="text"
                    />
                </div>
                <div>
                    <a href="/order/vod?pack=pro">
                        <button class="blue link text-capitalize">VOD (preconfigured)</button>
                    </a>
                </div>
                <hr />
                <div>
                    <a href="/order/vps_only">
                        <button class="blue link text-capitalize">VPS (empty)</button>
                    </a>
                </div>
                <div>
                    <a
                        href="/order/vps_only?vps_type=managed&domain_name=My%20VPS&disk_size=300&pack_id=98&hosting_number=5&site_number=20"
                    >
                        <button class="blue link text-capitalize">VPS (preconfigured)</button>
                    </a>
                </div>
                <div>
                    <a [href]="'/order/vps/' + product_vpsid">
                        <button [disabled]="!product_vpsid" class="blue link text-capitalize">
                            VPS (change_offer)
                        </button>
                    </a>
                    <input [(ngModel)]="product_vpsid" placeholder="vps id" type="text" />
                </div>
                <hr />
                <div>
                    <a href="/order2/domain?hosting_order=true">
                        <button class="blue link text-capitalize">Hosting</button>
                    </a>
                </div>
                <hr />

                <div>
                    <a href="/order/website_builder">
                        <button class="blue link text-capitalize">Infomaniak Site</button>
                    </a>
                </div>
                <div>
                    <a href="/order/website_builder/{{ siteBuilderId }}">
                        <button [disabled]="!siteBuilderId" class="blue link text-capitalize">
                            Infomaniak Site (change_offer)
                        </button>
                    </a>
                    <input [(ngModel)]="siteBuilderId" placeholder="id infomaniak site" type="text" />
                </div>
                <hr />
                <div>
                    <a href="/order/public_cloud">
                        <button class="blue link text-capitalize">Public Cloud</button>
                    </a>
                </div>
                <hr />
                <div>
                    <a href="/order/email_hosting/{{ mailServiceId }}">
                        <button [disabled]="!mailServiceId" class="blue link text-capitalize">
                            Mail Service (change_offer)
                        </button>
                    </a>
                    <input [(ngModel)]="mailServiceId" placeholder="id mail service" type="text" />
                </div>
                <hr />
                <div>
                    <a href="/order2/ksuite">
                        <button class="blue link text-capitalize">KSuite</button>
                    </a>
                </div>
                <div>
                    <a href="/order2/my_ksuite">
                        <button class="blue link text-capitalize">myKSuite</button>
                    </a>
                </div>
                <div>
                    <a href="/order/ksuite?migration=drive">
                        <button class="blue link text-capitalize">KSuite (kdrive Migration)</button>
                    </a>
                </div>
                <hr />
                <div>
                    <a href="/order2/ksuite/{{ ksuiteId }}">
                        <button [disabled]="!ksuiteId" class="blue link text-capitalize">KSuite (change_offer)</button>
                    </a>
                    <input [(ngModel)]="ksuiteId" placeholder="id ksuite" type="text" />
                </div>
                <hr />

                <div>
                    <a href="/order/streaming_audio/">
                        <button class="blue link text-capitalize">Radio (empty)</button>
                    </a>
                </div>
                <div>
                    <a href="/order/streaming_audio?pack=basic&period=1">
                        <button class="blue link text-capitalize">Radio (preconfigured)</button>
                    </a>
                </div>
                <div>
                    <a href="/order/streaming_audio/{{ product_radioId }}">
                        <button [disabled]="!product_radioId" class="blue link text-capitalize">
                            Radio (change_offer)
                        </button>
                    </a>
                    <input
                        (keyup.enter)="onEnter('/order/streaming_audio/' + product_radioId)"
                        [(ngModel)]="product_radioId"
                        placeholder="product id"
                        type="text"
                    />
                </div>
                <hr />
                <div>
                    <a href="/order2/support_premium">
                        <button class="blue link text-capitalize">Support Premium</button>
                    </a>
                </div>
                <hr />
                <div>
                    <a href="/order2/support_premium/{{ supportPremiumId }}">
                        <button [disabled]="!supportPremiumId" class="blue link text-capitalize">
                            Support Premium (change_offer)
                        </button>
                    </a>
                    <input [(ngModel)]="supportPremiumId" placeholder="id support premium" type="text" />
                </div>
            </div>
        </div>
    </div>
</section>
