import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { I18nService } from '@app/core';
import { LoaderService } from '@app/core/loader.service';
import { Cart } from '@app/models/cart';
import { NumberToConvertExtended } from '@app/shared/currency.pipe';
import { SwitchAccount, SwitchAccountDenied } from '@app/store/actions/account.action';
import {
  AddItem,
  AddItemOption,
  AddSubItem,
  AddTempContact,
  BulkAddItemOption,
  BulkRemoveItem,
  BulkRemoveItemOption,
  BulkRemoveSubItem,
  DomainBulkAdd,
  DomainBulkAddSubItems,
  DomainBulkRemoveSubItems,
  DomainBulkUpdate,
  GetCGU,
  InitCart,
  RemoveItem,
  RemoveItemCancelled,
  RemoveItemOption,
  RemoveItemOptionSuccess,
  RemoveItemSuccess,
  RemoveSubItem,
  ResetItem,
  SetLangDispatched,
  SetLangSuccessful,
  SetNavigation,
  SetPromo,
  UpdateItem,
  UpdatePromo,
  UpdateSubItem,
  UpdateTempContact,
  VatSwitchToggle,
  VatUpdate,
} from '@app/store/actions/cart.action';
import { NotificationService } from '@infomaniak/angular-common';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { cloneDeep } from 'lodash';
import { of, throwError } from 'rxjs';
import { catchError, map, share, tap } from 'rxjs/operators';

import { Item } from '@app/models/item';
import { DeletePromo, UpdateCartEmail } from '../actions/cart.action';
import { DeleteDialogComponent } from '../dialogs/delete.component';
import { SwitchDialogComponent } from '../dialogs/switch.component';
import { CartStateModel, responseType } from '../model/state.model';
import { AutoLogin, CheckAccount, CreateAccount, CreateUserAndAccount, Login } from './../actions/account.action';
import { Account } from '@app/models/account';
import { currency } from '@infomaniak/currency';
import { isNullish } from '@infomaniak/ngx-helpers';

@Injectable()
@State<CartStateModel>({
  name: 'cartState',
  defaults: {
    cart: null,
    accounts: null,
    links: null,
    duplicateError: null,
    cgu: null,
    invitation: null,
  },
})
export class CartState {
  once = false;
  lastUpdate: any = null;

  constructor(
    private http: HttpClient,
    private router: Router,
    private dialog: MatDialog,
    private lang: I18nService,
    private notificationService: NotificationService,
    private loader: LoaderService,
    private ngZone: NgZone
  ) {}

  @Selector()
  static accounts(state: CartStateModel) {
    return state.accounts;
  }

  @Selector()
  static links(state: CartStateModel) {
    return state.links;
  }

  @Selector()
  static cgu(state: CartStateModel) {
    return state.cgu;
  }

  @Selector()
  static invitationContext(state: CartStateModel) {
    return state.invitation;
  }

  @Selector()
  static promo(state: CartStateModel) {
    const item = this.getCurrentItem(state) || { discounts: [] as any[] };
    return state.cart.discounts.concat(item.discounts);
    // TODO : CONCAT WITH
  }

  @Selector()
  static getCart(state: CartStateModel) {
    return state.cart;
  }

  @Selector()
  static getCartCurrency(state: CartStateModel): currency {
    if (isNullish(state.cart?.setup?.currency_id)) {
      return 'chf';
    }
    return state.cart.setup.currency_id === 1 ? 'chf' : 'euro';
  }

  static getCartId() {
    return createSelector([CartState], (s: CartStateModel) => {
      return s.cart.setup.uuid;
    });
  }

  @Selector()
  static item(state: CartStateModel): Item {
    return this.getCurrentItem(state);
  }

  @Selector()
  static items(state: CartStateModel) {
    return state.cart.items;
  }

  @Selector()
  static navigation(state: CartStateModel) {
    return state.cart.navigation;
  }

  @Selector()
  static attributes(state: CartStateModel) {
    return state.cart.attributes;
  }

  @Selector()
  static referer(state: CartStateModel) {
    return state.cart.setup.history_back;
  }

  @Selector()
  static price(state: CartStateModel): NumberToConvertExtended {
    return {
      amountVAT: state.cart.prices.total.incl_vat,
      amountNoVAT: state.cart.prices.total.excl_vat,
    };
  }

  @Selector()
  static tax(state: CartStateModel): number {
    return state.cart.prices.total.vat;
  }

  @Selector()
  static prorata(state: CartStateModel) {
    return {
      amountVAT: state.cart.option_prorata.incl_vat,
      amountNoVAT: state.cart.option_prorata.excl_vat,
    };
  }

  @Selector()
  static priceBeforeDiscount(state: CartStateModel): NumberToConvertExtended {
    return {
      amountVAT: state.cart.prices.total_before_discount.incl_vat,
      amountNoVAT: state.cart.prices.total_before_discount.excl_vat,
    };
  }

  @Selector()
  static status(state: CartStateModel) {
    return state.cart.setup.status;
  }

  @Selector()
  static conditions(state: CartStateModel) {
    return state.cart.conditions;
  }

  @Selector()
  static summary(state: CartStateModel) {
    return state.cart.summary;
  }

  @Selector()
  static duplicateErrors(state: CartStateModel) {
    return state.duplicateError;
  }

  @Selector()
  static getCurrentAccount(state: CartStateModel): Account {
    return state.accounts.find((account) => account.id === window['CURRENT_ACCOUNT']?.id);
  }

  static getCurrentItem(state: CartStateModel) {
    return state.cart && state.cart.items.length
      ? state.cart.items.find(
          (item) => item.setup.id === state.cart.navigation.workflow.find((step) => !!step.cart_item_id).cart_item_id
        )
      : null;
  }

  @Action(InitCart)
  initCart(
    { getState, setState }: StateContext<CartStateModel>,
    { withCart = true, forceRefresh = false, forceProductNumber = false }: InitCart
  ) {
    if (!this.once || forceRefresh) {
      this.once = true;
      forceRefresh = window['CURRENT_STATE'].includes('order') && forceRefresh;
      const withValues = [];
      if (withCart || forceRefresh) {
        withValues.push('cart');
        if (typeof withCart === 'string') {
          withValues.push(withCart);
        }
        if (forceProductNumber) {
          withValues.push('nb_products');
        }
      }
      const paramWith = withValues.length > 0 ? `&with=${withValues.join(',')}` : '';
      const paramXREF = window && window['XREF'] ? `&xref=${encodeURIComponent(window['XREF'])}` : '';
      return this.http.get(`/api/profile/me?lang=${this.lang.language}${paramWith}${paramXREF}`).pipe(
        tap((response: any) => {
          if (!response || response.result !== 'success') {
            this.ngZone.run(() => {
              this.router.navigateByUrl('/error', {
                state: { custom_error: 'Sorry! Please contact support or try again later.' },
              });
            });
            // TODO: Redirect to error page
          }
          if (
            response &&
            response.data &&
            response.data.cart &&
            response.data.cart['code'] &&
            response.data.cart['code'] === 'admin_access_denied'
          ) {
            this.ngZone.run(() => {
              this.router.navigate(['/change_account']);
            });
          }
        }),
        map((result: any) => result.data),
        tap((result) => {
          const storage = sessionStorage || localStorage || null;
          if (storage) {
            try {
              storage.setItem('profile', JSON.stringify(result));
            } catch {} // not needed if it fail no worry
          }
          setState({
            cart: result.cart,
            accounts: result.accounts,
            links: result.links,
            cgu: result.cgu,
            invitation: result.invitation ? result.invitation : null,
          });
          document.dispatchEvent(
            new CustomEvent('updateCart', {
              detail: result.cart || [],
            })
          );
        })
      );
    }
  }

  @Action(AddItem)
  addItem({ getState, patchState }: StateContext<CartStateModel>, { payload, body }: AddItem) {
    const ctx = getState();
    this.loader.item.set(true);
    return this.http.post('/api/order/cart/' + ctx.cart.setup.uuid + '/item/' + payload + responseType, body).pipe(
      map((response: any) => response.data),
      tap(({ items, prices, setup, attributes, fs, discounts, conditions, navigation, summary, error }: any) => {
        // Tracking
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'addToCart',
          ecommerce: {
            currencyCode: window['CONST_CURRENCY']?.label,
            add: {
              products: [
                {
                  name: body?.domain_name?.split('.').pop(), // regex pour masquer le domaine recherché mais garder le TLD
                  id: items?.[0]?.setup?.id,
                  price: prices?.total?.incl_vat,
                  brand: 'Infomaniak',
                  category: payload, // domain, hosting, etc.
                  quantity: 1,
                },
              ],
            },
          },
        });

        const oldcart = { ...ctx.cart };
        delete oldcart['error'];
        const cart = {
          ...oldcart,
          attributes: attributes || ctx.cart.attributes,
          conditions: conditions || ctx.cart.conditions,
          navigation: navigation || ctx.cart.navigation,
          discounts: discounts || ctx.cart.discounts,
          fs: fs || ctx.cart['fs'],
          items: items || ctx.cart.items,
          prices: prices || ctx.cart.prices,
          setup: setup || ctx.cart.setup,
          summary: summary || ctx.cart.summary,
        };
        if (error) {
          cart['error'] = error;
        }
        patchState({
          cart: cart,
        });
        document.dispatchEvent(
          new CustomEvent('updateCart', {
            detail: cart || [],
          })
        );

        this.loader.item.set(false);
      })
    );
  }

  @Action(AddItemOption)
  addItemOption(
    { getState, patchState, dispatch }: StateContext<CartStateModel>,
    { item, item_option_type }: AddItemOption
  ) {
    const ctx = getState();
    return this.http
      .post('/api/order/cart/' + ctx.cart.setup.uuid + '/item/' + item.setup.id + '/item_option', { item_option_type })
      .pipe(
        map((response: any) => response.data),
        tap((newCart: Cart) => {
          patchState({
            cart: { ...ctx.cart, ...newCart },
          });

          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'addToCart',
            ecommerce: {
              currencyCode: window['CONST_CURRENCY']?.label,
              add: {
                products: [
                  {
                    name: item?.attributes?.domain_name?.split('.').pop(), // regex pour masquer le domaine recherché mais garder le TLD
                    id: item?.setup?.id,
                    price: item?.prices?.total?.incl_vat,
                    brand: 'Infomaniak',
                    category: item_option_type, // domain, hosting, etc.
                    quantity: 1,
                  },
                ],
              },
            },
          });

          document.dispatchEvent(
            new CustomEvent('updateCart', {
              detail: newCart || [],
            })
          );
        })
      )
      .toPromise();
  }

  @Action(RemoveItem)
  remove(
    { getState, patchState, dispatch }: StateContext<CartStateModel>,
    { payload, from_web_component }: RemoveItem
  ) {
    const ctx = getState();
    const cartItem = ctx.cart.items.find((item) => item.setup.id === payload.setup.id);
    this.loader.item.set(true);

    const deleteItem = () =>
      this.http
        .delete('/api/order/cart/' + ctx.cart.setup.uuid + '/item/' + payload.setup.id + responseType)
        .pipe(
          map((response: any) => response.data),
          tap((newCart: Cart) => {
            // Tracking
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: 'removeFromCart',
              ecommerce: {
                remove: {
                  products: [
                    {
                      name: cartItem?.setup?.name?.split('.').pop(), // regex pour masquer le domaine recherché mais garder le TLD
                      id: cartItem?.setup?.id,
                      price: cartItem?.prices?.total?.incl_vat,
                      brand: 'Infomaniak',
                      category: cartItem?.setup?.type, // domain, hosting, etc.
                      quantity: 1,
                    },
                  ],
                },
              },
            });
            patchState({
              cart: { ...ctx.cart, ...newCart },
            });
            this.loader.item.set(false);
            dispatch(new RemoveItemSuccess(cartItem));
            document.dispatchEvent(
              new CustomEvent('updateCart', {
                detail: newCart || [],
              })
            );
          }),
          catchError((err) => {
            this.loader.item.set(false);
            return throwError(err);
          })
        )
        .toPromise();

    if (from_web_component) {
      return deleteItem();
    }

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'dialog';
    dialogConfig.width = '600px';
    dialogConfig.data = {
      type: payload.setup.type || cartItem.setup.type,
      name: payload.setup.name || cartItem.setup.name,
    };
    const dialogRef = this.dialog.open(DeleteDialogComponent, dialogConfig);

    return dialogRef.afterClosed().subscribe((result) => {
      if (result === 'true') {
        return deleteItem();
      } else {
        dispatch(new RemoveItemCancelled(cartItem));
        document.dispatchEvent(new CustomEvent('loaderEmitEvent', { detail: false }));
        this.loader.item.set(false);
        return;
      }
    });
  }

  @Action(RemoveItemOption)
  removeItemOption(
    { getState, patchState, dispatch }: StateContext<CartStateModel>,
    { item, item_option_type }: RemoveItemOption
  ) {
    const ctx = getState();
    return this.http
      .delete('/api/order/cart/' + ctx.cart.setup.uuid + '/item/' + item.setup.id + '/item_option', {
        params: { item_option_type },
      })
      .pipe(
        map((response: any) => response.data),
        tap((newCart: Cart) => {
          patchState({
            cart: { ...ctx.cart, ...newCart },
          });
          dispatch(new RemoveItemOptionSuccess(item));
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'removeFromCart',
            ecommerce: {
              remove: {
                products: [
                  {
                    name: item?.attributes?.domain_name?.split('.').pop(), // regex pour masquer le domaine recherché mais garder le TLD
                    id: item?.setup?.id,
                    price: item?.prices?.total?.incl_vat,
                    brand: 'Infomaniak',
                    category: item_option_type, // domain, hosting, etc.
                    quantity: 1,
                  },
                ],
              },
            },
          });
          document.dispatchEvent(
            new CustomEvent('updateCart', {
              detail: newCart || [],
            })
          );
        })
      )
      .toPromise();
  }

  @Action(RemoveItemOptionSuccess)
  removeItemOptionSuccess({ item }: RemoveItemOptionSuccess) {
    return item;
  }

  @Action(BulkAddItemOption)
  bulkAddItemOption({ getState, patchState }: StateContext<CartStateModel>, { payload }: BulkAddItemOption) {
    const ctx = getState();
    return this.http.post('/api/order/cart/' + ctx.cart.setup.uuid + '/item/item_options', payload).pipe(
      map((response: any) => response.data),
      tap((newCart: Cart) => {
        patchState({
          cart: { ...ctx.cart, ...newCart },
        });
        document.dispatchEvent(
          new CustomEvent('updateCart', {
            detail: newCart || [],
          })
        );
      })
    );
  }

  @Action(BulkRemoveItem)
  bulkRemove({ getState, patchState, dispatch }: StateContext<CartStateModel>, { payload }: BulkRemoveItem) {
    const ctx = getState();
    const length = payload.length - 1;
    const item_ids = payload.map((item) => item.setup.id);
    return this.http
      .post('/api/order/cart/' + ctx.cart.setup.uuid + '/item/bulk_delete' + responseType, { item_ids: item_ids })
      .pipe(
        map((response: any) => response.data),
        tap((newCart: Cart) => {
          patchState({
            cart: { ...ctx.cart, ...newCart },
          });
          payload.forEach((item) => {
            dispatch(new RemoveItemSuccess(item));
          });
          document.dispatchEvent(
            new CustomEvent('updateCart', {
              detail: newCart || [],
            })
          );
        })
      );
  }

  @Action(BulkRemoveItemOption)
  bulkRemoveItemOption({ getState, patchState }: StateContext<CartStateModel>, { payload }: BulkRemoveItemOption) {
    const ctx = getState();
    return this.http.post('/api/order/cart/' + ctx.cart.setup.uuid + '/item/delete_item_options', payload).pipe(
      map((response: any) => response.data),
      tap((newCart: Cart) => {
        patchState({
          cart: { ...ctx.cart, ...newCart },
        });
        document.dispatchEvent(
          new CustomEvent('updateCart', {
            detail: newCart || [],
          })
        );
        this.loader.item.set(false);
      })
    );
  }

  @Action(RemoveItemSuccess)
  removeItemSuccess({ cartItem }: RemoveItemSuccess) {
    return cartItem;
  }

  @Action(RemoveItemCancelled)
  removeItemCancelled({ cartItem }: RemoveItemCancelled) {
    return cartItem;
  }

  @Action(UpdateItem)
  update({ getState, patchState }: StateContext<CartStateModel>, { payload }: UpdateItem) {
    this.loader.item.set(true);
    try {
      const item = cloneDeep(payload);
      const ctx = getState();
      if (JSON.stringify(payload) !== this.lastUpdate) {
        this.lastUpdate = JSON.stringify(payload);
        return this.http
          .put('/api/order/cart/' + ctx.cart.setup.uuid + '/item/' + payload.setup.id, JSON.stringify(item.attributes))
          .pipe(
            map((response: any) => response.data),
            tap((newCart: Cart) => {
              patchState({
                cart: {
                  ...ctx.cart,
                  navigation: newCart.navigation,
                  items: newCart.items,
                  prices: newCart.prices,
                  conditions: newCart.conditions,
                  discounts: newCart.discounts,
                  setup: newCart.setup,
                  summary: newCart.summary,
                },
              });
              this.loader.item.set(false);
              document.dispatchEvent(
                new CustomEvent('updateCart', {
                  detail: newCart || [],
                })
              );
            }),
            share(),
            catchError((err) => {
              this.loader.item.set(false);
              return throwError(err);
            })
          );
      } else {
        document.dispatchEvent(new CustomEvent('loaderEmitEvent', { detail: false }));
        this.loader.item.set(false);
      }
    } catch (e) {
      console.warn(e);
      document.dispatchEvent(new CustomEvent('loaderEmitEvent', { detail: false }));
      this.loader.item.set(false);
    }
  }

  @Action(ResetItem)
  reset({ getState, patchState }: StateContext<CartStateModel>, { payload }: ResetItem) {
    this.loader.item.set(true);
    try {
      const ctx = getState();
      return this.http
        .put('/api/order/cart/' + ctx.cart.setup.uuid + '/item/' + payload.setup.id + '/reset', { trial: true })
        .pipe(
          map((response: any) => response.data),
          tap((newCart: Cart) => {
            patchState({
              cart: {
                ...ctx.cart,
                navigation: newCart.navigation,
                items: newCart.items,
                prices: newCart.prices,
                setup: newCart.setup,
                summary: newCart.summary,
              },
            });
            this.loader.item.set(false);
            document.dispatchEvent(
              new CustomEvent('updateCart', {
                detail: newCart || [],
              })
            );
          }),
          share(),
          catchError((err) => {
            this.loader.item.set(false);
            return throwError(err);
          })
        );
    } catch {
      this.loader.item.set(false);
    }
  }

  @Action(AddSubItem)
  addSubItem({ getState, patchState }: StateContext<CartStateModel>, { payload, body }: AddSubItem) {
    const ctx = getState();
    this.loader.item.set(true);
    return this.http
      .post(
        '/api/order/cart/' +
          ctx.cart.setup.uuid +
          '/item/' +
          payload.parent.setup.id +
          '/' +
          payload.subitemtype +
          responseType,
        body
      )
      .pipe(
        map((response: any) => response.data),
        tap(({ items, prices, setup, attributes, fs, discounts, conditions, navigation, summary, error }: any) => {
          // Tracking
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'addToCart',
            ecommerce: {
              currencyCode: window['CONST_CURRENCY']?.label,
              add: {
                products: [
                  {
                    name: (body.name || body?.domain_name)?.split('.').pop(), // regex pour masquer le domaine recherché mais garder le TLD
                    price: prices?.total?.incl_vat,
                    brand: 'Infomaniak',
                    category: payload.subitemtype, // domain, hosting, etc.
                    quantity: 1,
                  },
                ],
              },
            },
          });
          const oldcart = { ...ctx.cart };
          delete oldcart['error'];
          const cart = {
            ...oldcart,
            attributes: attributes || ctx.cart.attributes,
            conditions: conditions || ctx.cart.conditions,
            navigation: navigation || ctx.cart.navigation,
            discounts: discounts || ctx.cart.discounts,
            fs: fs || ctx.cart['fs'],
            items: items || ctx.cart.items,
            prices: prices || ctx.cart.prices,
            setup: setup || ctx.cart.setup,
            summary: summary || ctx.cart.summary,
          };
          if (error) {
            cart['error'] = error;
          }
          patchState({
            cart: cart,
          });
          document.dispatchEvent(
            new CustomEvent('updateCart', {
              detail: cart || [],
            })
          );

          this.loader.item.set(false);
        })
      );
  }

  @Action(UpdateSubItem)
  updateSub({ getState, patchState }: StateContext<CartStateModel>, { payload }: UpdateSubItem) {
    this.loader.item.set(true);
    const item = cloneDeep(payload.subitem);
    const ctx = getState();
    if (JSON.stringify(payload.subitem) !== this.lastUpdate) {
      this.lastUpdate = JSON.stringify(payload.subitem);
      return this.http
        .put(
          '/api/order/cart/' +
            ctx.cart.setup.uuid +
            '/item/' +
            payload.parent.setup.id +
            '/' +
            payload.subitem.setup.id,
          JSON.stringify(item.attributes)
        )
        .pipe(
          map((response: any) => response.data),
          tap((newCart: Cart) => {
            patchState({
              cart: {
                ...ctx.cart,
                navigation: newCart.navigation,
                items: newCart.items,
                prices: newCart.prices,
                conditions: newCart.conditions,
                discounts: newCart.discounts,
                setup: newCart.setup,
                summary: newCart.summary,
              },
            });
            document.dispatchEvent(
              new CustomEvent('updateCart', {
                detail: newCart || [],
              })
            );
            this.loader.item.set(false);
          }),
          share()
        );
    } else {
      document.dispatchEvent(new CustomEvent('loaderEmitEvent', { detail: false }));
      this.loader.item.set(false);
    }
  }

  @Action(RemoveSubItem)
  removeSub(
    { getState, patchState, dispatch }: StateContext<CartStateModel>,
    { payload, from_web_component }: RemoveSubItem
  ) {
    const ctx = getState();

    this.loader.item.set(true);
    const remove_item = () =>
      this.http
        .delete(
          '/api/order/cart/' +
            ctx.cart.setup.uuid +
            '/item/' +
            (payload.parent?.setup?.id || (payload.subitem?.setup as any)?.parentId) +
            '/' +
            payload.subitem.setup.id +
            responseType
        )
        .pipe(
          map((response: any) => response.data),
          tap((newCart: Cart) => {
            window.dataLayer.push({
              event: 'removeFromCart',
              ecommerce: {
                remove: {
                  products: [
                    {
                      name: payload?.subitem?.attributes?.domain_name?.split('.').pop(), // regex pour masquer le domaine recherché mais garder le TLD
                      id: payload?.subitem?.setup?.id,
                      price: payload?.subitem?.prices?.total?.incl_vat,
                      brand: 'Infomaniak',
                      category: payload?.subitem?.setup?.type, // domain, hosting, etc.
                      quantity: 1,
                    },
                  ],
                },
              },
            });
            patchState({
              cart: { ...ctx.cart, ...newCart },
            });
            dispatch(new RemoveItemSuccess(payload.subitem));
            document.dispatchEvent(
              new CustomEvent('updateCart', {
                detail: newCart || [],
              })
            );
            this.loader.item.set(false);
          })
        )
        .toPromise();

    if (from_web_component) {
      return remove_item();
    }

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'dialog';
    dialogConfig.width = '600px';
    dialogConfig.data = { type: payload.subitem.setup.type, name: payload.subitem.setup.name };
    const dialogRef = this.dialog.open(DeleteDialogComponent, dialogConfig);

    return dialogRef.afterClosed().subscribe((result) => {
      if (result === 'true') {
        return remove_item();
      } else {
        dispatch(new RemoveItemCancelled(payload.subitem));
        document.dispatchEvent(new CustomEvent('loaderEmitEvent', { detail: false }));
        this.loader.item.set(false);
        return;
      }
    });
  }

  @Action(BulkRemoveSubItem)
  bulkRemoveSub({ getState, patchState, dispatch }: StateContext<CartStateModel>, { payload }: BulkRemoveSubItem) {
    const ctx = getState();
    const body = { items: {} };
    payload.forEach((_p) => {
      if (body.items[_p.parent.setup.id] === undefined) {
        body.items[_p.parent.setup.id] = [];
      }
      body.items[_p.parent.setup.id].push(_p.subitem.setup.id);
    });
    this.loader.item.set(true);
    return this.http
      .post('/api/order/cart/' + ctx.cart.setup.uuid + '/item/bulk_delete_subs' + responseType, body)
      .pipe(
        map((response: any) => response.data),
        tap((newCart: Cart) => {
          // window.dataLayer.push({
          //     'event': 'remove_from_cart',
          //     'ecommerce': {
          //         'items': [{
          //             'item_name': payload?.subitem?.setup?.type, // regex pour masquer le domaine recherché mais garder le TLD
          //             'price': payload?.subitem?.prices?.total?.incl_vat,
          //             'item_brand': 'Infomaniak',
          //             'item_category': payload?.subitem?.setup?.type, // domain, hosting, etc.
          //             'quantity': '1'
          //         }]
          //     }
          // });
          patchState({
            cart: { ...ctx.cart, ...newCart },
          });
          payload.forEach((_p) => {
            dispatch(new RemoveItemSuccess(_p.subitem));
          });
          document.dispatchEvent(
            new CustomEvent('updateCart', {
              detail: newCart || [],
            })
          );
          this.loader.item.set(false);
        })
      );
  }

  @Action(SetPromo)
  setPromo({ getState, patchState }: StateContext<CartStateModel>, { payload }: SetPromo) {
    this.loader.item.set(true);
    try {
      const ctx = getState();
      return this.http
        .post(`/api/order/cart/${ctx.cart.setup.uuid}/condition`, {
          code: payload,
        })
        .pipe(
          map((response: any) => response.data),
          tap((newCart: Cart) => {
            patchState({
              cart: { ...ctx.cart, ...newCart },
            });
            this.loader.item.set(false);
            document.dispatchEvent(
              new CustomEvent('updateCart', {
                detail: newCart || [],
              })
            );
          }),
          catchError((err) => {
            this.loader.item.set(false);
            return throwError(err);
          })
        );
    } catch {
      this.loader.item.set(false);
    }
  }

  @Action(DeletePromo)
  deletePromo({ getState, patchState }: StateContext<CartStateModel>, { payload }: DeletePromo) {
    this.loader.item.set(true);
    try {
      const ctx = getState();
      return this.http.delete(`/api/order/cart/${ctx.cart.setup.uuid}/condition/${payload.id}`).pipe(
        map((response: any) => response.data),
        tap((newCart: Cart) => {
          patchState({
            cart: { ...ctx.cart, ...newCart },
          });
          this.loader.item.set(false);
          document.dispatchEvent(
            new CustomEvent('updateCart', {
              detail: newCart || [],
            })
          );
        }),
        catchError((err) => {
          this.loader.item.set(false);
          return throwError(err);
        })
      );
    } catch {
      this.loader.item.set(false);
    }
  }

  @Action(UpdatePromo)
  updatePromo({ getState, patchState }: StateContext<CartStateModel>, { discountId, payload }: UpdatePromo) {
    this.loader.item.set(true);
    try {
      const ctx = getState();
      return this.http.put(`/api/order/cart/${ctx.cart.setup.uuid}/condition/${discountId}`, payload).pipe(
        map((response: any) => response.data),
        tap((newCart: Cart) => {
          patchState({
            cart: { ...ctx.cart, ...newCart },
          });
          this.loader.item.set(false);
          document.dispatchEvent(
            new CustomEvent('updateCart', {
              detail: newCart || [],
            })
          );
        }),
        catchError((err) => {
          this.loader.item.set(false);
          return throwError(err);
        })
      );
    } catch {
      document.dispatchEvent(new CustomEvent('loaderEmitEvent', { detail: false }));
      this.loader.item.set(false);
    }
  }

  @Action(SetNavigation)
  setNavigation({ getState, patchState }: StateContext<CartStateModel>, { payload }: SetNavigation) {
    this.loader.set(true);
    try {
      const ctx = getState();
      const current_step = payload.current_step
        ? ctx.cart.navigation.workflow.find((x) => x.label === payload.current_step)
        : ctx.cart.navigation?.workflow?.find((x) => x.order === ctx.cart.navigation.current_step);

      // Tracking
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'viewed_checkout_step',
        checkout_step: ctx.cart.navigation?.workflow?.find((x) => x.order === current_step.order + payload.target)
          ?.order,
      });

      if (current_step) {
        let item;
        if (payload.goTo) {
          const route = payload.goTo.split('/').pop();
          item =
            ctx.cart.navigation.workflow.find((x) => x.name === route) ||
            ctx.cart.navigation.workflow.find((x) => x.type === route);
        } else {
          item = ctx.cart.navigation.workflow.find((x) => x.order === +current_step.order + payload.target);
        }
        if (item && item.name) {
          return this.http
            .put('/api/order/cart/' + ctx.cart.setup.uuid, JSON.stringify({ current_step: item.order }))
            .pipe(
              map((response: any) => response.data),
              tap((newCart: Cart) => {
                patchState({
                  cart: { ...ctx.cart, ...newCart },
                });
                this.loader.set(false);
              }),
              catchError((err) => {
                this.loader.item.set(false);
                return throwError(err);
              })
            );
        } else {
          if (payload.target === -1 && ctx.cart && ctx.cart.setup && ctx.cart.setup.history_back) {
            window.location.assign(ctx.cart.setup.history_back);
          } else {
            window.location.assign('/');
            // THERE IS NO MORE STEPS
            console.log('Targeting unknown step, cancel navigation', JSON.stringify(payload));
          }
        }
      }
    } catch {
      this.loader.set(false);
    }
  }

  /** ACCOUNTS METHOD */

  @Action(SwitchAccount)
  switchAccount({ getState, patchState, dispatch }: StateContext<CartStateModel>, { payload }: SwitchAccount) {
    const ctx = getState();
    const current_step =
      ctx.cart && ctx.cart.navigation && ctx.cart.navigation.workflow && ctx.cart.navigation.workflow.length > 0
        ? ctx.cart.navigation.workflow.find((x) => x.order === ctx.cart.navigation.current_step)
        : null;
    if (
      (current_step && current_step.name !== 'change_account') ||
      (ctx.cart && ctx.cart['result'] && ctx.cart['result'] === 'error')
    ) {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'dialog--medium';
      dialogConfig.data = {
        account: payload,
      };

      const dialogRef = this.dialog.open(SwitchDialogComponent, dialogConfig);

      return dialogRef.afterClosed().subscribe((result) => {
        if (result === 'true') {
          return this.http
            .put('api/profile/switch_account', JSON.stringify(payload))
            .pipe(
              tap((response: any) => {
                if (response.result === 'success') {
                  window.location.assign(response.data.redirect);
                } else {
                  this.notificationService.error(response);
                  console.error('Cant switch :' + response);
                }
              })
            )
            .toPromise();
        } else {
          dispatch(new SwitchAccountDenied());
          return;
        }
      });
    } else {
      return this.http
        .put('api/profile/switch_account', JSON.stringify(payload))
        .pipe(
          tap((response: any) => {
            if (response.result === 'success') {
              window.location.assign(response.data.redirect);
            } else {
              this.notificationService.error(response);
              console.error('Cant switch :' + response);
            }
          })
        )
        .toPromise();
    }
  }

  @Action(CheckAccount)
  checkAccount({ getState, patchState }: StateContext<CartStateModel>, { payload }: CheckAccount) {
    return this.http.post('/api/register/check_email', JSON.stringify(payload));
  }

  @Action(CreateAccount)
  createAccount({ getState, patchState }: StateContext<CartStateModel>, { payload, force }: CreateAccount) {
    this.loader.item.set(true);
    try {
      Object.keys(payload).forEach((key) => (payload[key] === null || payload[key] === '') && delete payload[key]);
      return this.http
        .post('/api/register/account' + (force ? '?bypass=true' : ''), JSON.stringify({ account: { ...payload } }))
        .pipe(
          tap((response: any) => {
            if (response.result === 'success') {
              this.loader.set(true);
              window.location.assign(response.data.redirect);
            }
          }),
          catchError((err) => {
            this.loader.item.set(false);
            if (err.error.error.code === 'duplicate_account') {
              patchState({ duplicateError: err.error.error });
            }
            return throwError(err);
          })
        );
    } catch {
      this.loader.item.set(false);
    }
  }

  @Action(CreateUserAndAccount)
  createUserAndAccount(
    { getState, patchState }: StateContext<CartStateModel>,
    { payload, force }: CreateUserAndAccount
  ) {
    this.loader.item.set(true);
    try {
      return this.http
        .post('/api/register/user_and_account' + (force ? '?bypass=true' : ''), JSON.stringify(payload))
        .pipe(
          tap((response: any) => {
            if (response.result === 'success') {
              this.loader.set(true);
              this.autoLogin({
                url: response.data.login,
                redirect: response.data.redirect,
                user: payload.user.email,
                password: payload.user.password,
              });
            } else {
              console.error('its a nop' + response);
              this.loader.item.set(false);
            }
          }),
          catchError((err) => {
            if (err.error.error.code === 'duplicate_account') {
              patchState({ duplicateError: err.error.error });
            }
            return throwError(err);
          })
        );
    } finally {
      this.loader.item.set(false);
    }
  }

  @Action(UpdateCartEmail)
  updateCartEmail({ getState }: StateContext<CartStateModel>, { payload }: UpdateCartEmail) {
    const ctx = getState();
    return this.http.put('/api/order/cart/' + ctx.cart.setup.uuid, JSON.stringify({ email: payload }));
  }

  @Action(Login)
  Login({ getState, patchState }: StateContext<CartStateModel>, { payload }: Login) {
    this.loader.set(true);
    window.location.assign(window.location.origin + '/auth/login?email=' + payload.email);
  }

  @Action(AutoLogin)
  autoLogin(payload: any) {
    this.loader.set(true);

    try {
      const body = new URLSearchParams();
      body.set('login', payload.user);
      body.set('password', payload.password);
      body.set('ajax', '1');
      body.set('r', payload.redirect);
      return this.http
        .post(payload.url, body.toString(), {
          headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
          withCredentials: true,
        })
        .pipe(
          tap((response: any) => {
            if (payload.redirect && payload.redirect !== 'none') {
              window.location.assign(response.data.redirect);
            } else {
              this.loader.set(false);
            }
          }),
          catchError((err) => {
            this.loader.item.set(false);
            return throwError(err);
          })
        )
        .toPromise();
    } catch {
      this.loader.set(false);
    }
  }

  @Action(SetLangDispatched)
  SetLangDispatched({ getState, patchState }: StateContext<CartStateModel>) {
    this.loader.set(true);
  }

  @Action(SetLangSuccessful)
  SetLangSuccessful({ getState, patchState }: StateContext<CartStateModel>) {
    this.loader.set(false);
  }

  @Action(GetCGU)
  getCGU({ getState, patchState }: StateContext<CartStateModel>, {}: GetCGU) {
    const ctx = getState();
    return this.http.get('/api/order/cart/' + ctx.cart.setup.uuid + '/checkout').pipe(
      tap((x: any) => {
        patchState({ cgu: x.data.cgus });
      })
    );
  }

  @Action(AddTempContact)
  AddTempContact({ patchState, getState }: StateContext<CartStateModel>, { payload }: AddTempContact) {
    const ctx = getState();
    return this.http
      .post('/api/order/cart/' + ctx.cart.setup.uuid + '/domaincontact', { contact: payload })
      .pipe(
        map((response: any) => response.data),
        tap(({ attributes, setup }: Cart) => {
          const cart = { ...ctx.cart };
          delete cart['error'];
          const newAttributes = { ...cart.attributes, contacts: attributes['contacts'] };
          patchState({
            cart: { ...cart, attributes: newAttributes, setup: setup },
          });
        })
      )
      .toPromise();
  }

  @Action(UpdateTempContact)
  UpdateTempContact({ patchState, getState }: StateContext<CartStateModel>, { payload }: AddTempContact) {
    const ctx = getState();
    return this.http
      .put('/api/order/cart/' + ctx.cart.setup.uuid + '/domaincontact', { contact: payload })
      .pipe(
        map((response: any) => response.data),
        tap(({ attributes, setup }: Cart) => {
          const cart = { ...ctx.cart };
          delete cart['error'];
          const newAttributes = { ...cart.attributes, contacts: attributes['contacts'] };
          patchState({
            cart: { ...cart, attributes: newAttributes, setup: setup },
          });
        })
      )
      .toPromise();
  }

  @Action(DomainBulkAdd)
  domainBulkAdd({ getState, patchState }: StateContext<CartStateModel>, { payload }: DomainBulkAdd) {
    const ctx = getState();
    return this.http.post('/api/order/cart/' + ctx.cart.setup.uuid + '/item/domain/bulk', payload).pipe(
      map((response: any) => response.data),
      tap((newCart: Cart) => {
        patchState({
          cart: {
            ...ctx.cart,
            items: newCart.items,
            prices: newCart.prices,
            conditions: newCart.conditions,
            discounts: newCart.discounts,
            setup: newCart.setup,
            summary: newCart.summary,
          },
        });
        document.dispatchEvent(
          new CustomEvent('updateCart', {
            detail: newCart || [],
          })
        );
      })
    );
  }

  @Action(DomainBulkUpdate)
  domainBulkUpdate({ getState, patchState }: StateContext<CartStateModel>, { payload }: DomainBulkUpdate) {
    const ctx = getState();
    return this.http.put('/api/order/cart/' + ctx.cart.setup.uuid + '/item', payload).pipe(
      map((response: any) => response.data),
      tap((newCart: Cart) => {
        patchState({
          cart: {
            ...ctx.cart,
            items: newCart.items,
            prices: newCart.prices,
            conditions: newCart.conditions,
            discounts: newCart.discounts,
            setup: newCart.setup,
            summary: newCart.summary,
          },
        });
        document.dispatchEvent(
          new CustomEvent('updateCart', {
            detail: newCart || [],
          })
        );
      })
    );
  }

  @Action(DomainBulkAddSubItems)
  domainBulkAddSubItem({ getState, patchState }: StateContext<CartStateModel>, { payload }: DomainBulkAddSubItems) {
    const ctx = getState();
    const urlPart = payload.hasOwnProperty('urlPart') ? payload.urlPart : 'domain';
    this.loader.item.set(true);
    return this.http.post('/api/order/cart/' + ctx.cart.setup.uuid + '/item/' + urlPart + '/sub_items', payload).pipe(
      map((response: any) => response.data),
      tap(({ items, prices, setup, attributes, fs, discounts, conditions, summary, error }: any) => {
        const oldcart = { ...ctx.cart };
        delete oldcart['error'];
        const cart = {
          ...oldcart,
          attributes: attributes || ctx.cart.attributes,
          conditions: conditions || ctx.cart.conditions,
          discounts: discounts || ctx.cart.discounts,
          fs: fs || ctx.cart['fs'],
          items: items || ctx.cart.items,
          prices: prices || ctx.cart.prices,
          setup: setup || ctx.cart.setup,
          summary: summary || ctx.cart.summary,
        };
        if (error) {
          cart['error'] = error;
        }
        patchState({
          cart: cart,
        });
        document.dispatchEvent(
          new CustomEvent('updateCart', {
            detail: cart || [],
          })
        );
        this.loader.item.set(false);
      })
    );
  }

  @Action(DomainBulkRemoveSubItems)
  domainBulkRemoveSubItem(
    { getState, patchState }: StateContext<CartStateModel>,
    { payload }: DomainBulkRemoveSubItems
  ) {
    const ctx = getState();
    return this.http
      .post('/api/order/cart/' + ctx.cart.setup.uuid + '/item/domain/sub_items/bulk_delete', payload)
      .pipe(
        map((response: any) => response.data),
        tap((newCart: Cart) => {
          patchState({
            cart: { ...ctx.cart, ...newCart },
          });
          document.dispatchEvent(
            new CustomEvent('updateCart', {
              detail: newCart || [],
            })
          );
        })
      );
  }

  @Action(VatSwitchToggle)
  vatSwitch({ getState, patchState }: StateContext<CartStateModel>, { inclVat, markForCheck }: VatSwitchToggle) {
    if (localStorage) {
      localStorage.setItem('incl_vat', inclVat);
    }
    document.dispatchEvent(
      new CustomEvent('vatSwitchToggle', {
        detail: { incl_vat: inclVat === 'true' },
      })
    );
    return of({ inclVat: inclVat, markForCheck: markForCheck });
  }

  @Action(VatUpdate)
  vatUpdate({ getState, patchState }: StateContext<CartStateModel>, { cart }: VatUpdate) {
    const ctx = getState();
    patchState({
      cart: { ...ctx.cart, prices: { ...ctx.cart.prices, ...cart.prices } },
    });
  }
}
