import { IndexComponent } from './index/index.component';
import { SIGNUP_ROUTES } from '@app/signup/signup.routes';

export const routes = [
  {
    path: '',
    children: [
      ...SIGNUP_ROUTES,
      {
        path: 'order',
        loadChildren: () => import('./order/order.module').then((m) => m.OrderModule),
      },
      {
        path: 'order2',
        loadChildren: () => import('./order/order.module').then((m) => m.OrderModule),
        data: { newDesign: true },
      },
      {
        path: 'b2b_3ds_customer',
        loadChildren: () => import('./b2b-3ds/b2b-3ds.module').then((m) => m.B2b3dsModule),
      },
      {
        path: 'summary',
        loadChildren: () => import('./summary/summary.module').then((m) => m.SummaryModule),
      },
      {
        path: 'satisfaction',
        loadChildren: () => import('./satisfaction/satisfaction.module').then((m) => m.SatisfactionModule),
      },
      {
        path: 'index',
        component: IndexComponent,
      },
      {
        path: 'change_account',
        loadChildren: () => import('./change-account/change-account.module').then((m) => m.ChangeAccountModule),
      },
      {
        path: 'invitation',
        children: [
          {
            path: 'success',
            loadChildren: () => import('./invitation/invitation.module').then((m) => m.InvitationModule),
          },
          {
            path: 'webmail1',
            loadChildren: () =>
              import('./invitation/invitation-webmail-migration/invitation-webmail-migration.module').then(
                (m) => m.InvitationWebmailMigrationModule
              ),
          },
          {
            path: ':type',
            children: [
              {
                path: '',
                loadChildren: () => import('./signup/signup.module').then((m) => m.SignupModule),
              },
              {
                path: ':key',
                loadChildren: () => import('./signup/signup.module').then((m) => m.SignupModule),
              },
            ],
          },
        ],
      },
      {
        path: 'ACCOUNT/workspace/entertainment/drive',
        loadChildren: () => import('./invitation/invitation.module').then((m) => m.InvitationModule),
      },
      {
        path: 'error',
        loadChildren: () => import('./error/error.module').then((m) => m.ErrorModule),
      },
      {
        path: 'update/order/domain',
        loadChildren: () => import('./edit-domain-order/edit-domain-order.module').then((m) => m.EditDomainOrderModule),
      },
      {
        path: 'support/:id',
        loadChildren: () => import('./support/support.module').then((m) => m.SupportModule),
      },
      {
        path: 'secure-profile',
        loadChildren: () => import('./secure-profile/secure-profile.module').then((m) => m.SecureProfileModule),
      },
      {
        path: 'student',
        loadChildren: () => import('./student/student.module').then((m) => m.StudentModule),
      },
      {
        path: 'student/renew',
        loadChildren: () => import('./student/student.module').then((m) => m.StudentModule),
      },
    ],
  },
];
