import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { InitCart } from '@app/store/actions/cart.action';
import { CartState } from '@app/store/state/cart.state';
import { DomainRecommendationTunnels, NewDomainRecommendationTunnels } from '@app/models/items/domain-recommendations';

@Injectable()
export class ProfileResolve implements Resolve<any> {
  constructor(private store: Store) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const params = [];

    if (window?.['CURRENT_ORDER_SERVICE'] === 'llm') {
      params.push('ai-tools');
    }

    const isKsuiteRelatedTunnel =
      window?.['CURRENT_ORDER_SERVICE'].includes('ksuite') && !window?.['CURRENT_ORDER_SERVICE'].includes('my_ksuite');
    const isDomainRelatedTunnel = [...DomainRecommendationTunnels, ...NewDomainRecommendationTunnels].includes(
      window?.['CURRENT_ORDER_SERVICE']
    );
    if (isKsuiteRelatedTunnel || isDomainRelatedTunnel) {
      params.push('ksuite');
    }

    const withParams = params.length > 0 ? params.join(',') : true;
    this.store.dispatch(new InitCart(withParams));
    return this.store.selectOnce(CartState.getCart);
  }
}
